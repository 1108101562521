import {
    createRouter,
    createMemoryHistory,
} from "vue-router";
import { Home, Task, Friend, Index, Hall, Game, Items, Error } from "../views";
import {
    DefaultLayout,
    MainLayout,
    BackgroundLayout,
    BackgroundLayout2,
    TopNavbarLayout,
} from "../layouts";

const routes = [
    {
        path: "/",
        name: "index",
        component: Index,
        meta: { layout: DefaultLayout },
    },

    {
        path: "/home",
        name: "home",
        component: Home,
        meta: { layout: BackgroundLayout },
    },
    {
        path: "/task",
        name: "task",
        component: Task,
        meta: { layout: MainLayout },
    },
    {
        path: "/hall",
        name: "hall",
        component: Hall,
        meta: { layout: BackgroundLayout2 },
    },
    {
        path: "/friend",
        name: "friend",
        component: Friend,
        meta: { layout: MainLayout },
    },
    {
        path: "/game",
        name: "game",
        component: Game,
        meta: { layout: DefaultLayout },
    },
    {
        path: "/error",
        name: "error",
        component: Error,
        meta: { layout: DefaultLayout },
    },
    {
        path: "/items/:type",
        component: Items,
        meta: { layout: TopNavbarLayout },
    },
];

const router = createRouter({
    history: createMemoryHistory(),
    routes,
});

export default router;
