import { defineStore } from "pinia";

export default defineStore("counter", {
    state: () => ({
        totalCoin: 0,
        idCount: null,
        widthProgress: 0,
    }),
 
});
