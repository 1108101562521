import axios from "axios";
import { toast } from "vue3-toastify";

const backendURL = import.meta.env.VITE_BACKEND_URL;
const botName = import.meta.env.VITE_BOT_NAME;
const isLocal = import.meta.env.DEV;

const client = axios.create({
    baseURL: backendURL + "/api",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});
if (isLocal) {
    client.defaults.baseURL = "/api";
}

export const getListItems = async (token) => {
    try {
        const response = await client.get(`/items`, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
        });
        if (response) return response;
    } catch (error) {
        console.error("Error getting list items:", error);
        const msg = error?.response?.data?.message;
        if (typeof msg == "string") {
            toast.error(msg || "Something error");
        }
    }
};

export const buyItem = async (itemId, token) => {
    try {
        const response = await client.post(`/items/buy/${itemId}`, null, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
        });
        if (response) return response;
    } catch (error) {
        console.error("Error buy item", error);
        const msg = error?.response?.data?.message;
        if (typeof msg == "string") {
            toast.error(msg || "Something error");
        }
    }
};
