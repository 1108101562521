<script setup>
import { useRouter } from "vue-router";
import { TonConnectUIProvider } from "@townsquarelabs/ui-vue";
import { onMounted } from "vue";
import useNowStore from "./stores/nowStore.js";

// Environment Variables
const baseURL = import.meta.env.VITE_BACKEND_URL;

const options = {
    manifestUrl: `${baseURL}/tonconnect-manifest.json`,
};

// Vue Router
const router = useRouter();
const nowStore = useNowStore();

// Handle DOM-related logic after the component is mounted
onMounted(() => {
    // Check if Telegram WebApp object exists and run the logic
    if (window?.Telegram?.WebApp) {
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.disableVerticalSwipes();
    }

    // Reload page on Vite preload error
    window.addEventListener("vite:preloadError", (event) => {
        event.preventDefault();
        window.location.reload();
    });

    window.addEventListener("visibilitychange", async () => {
        if (document.visibilityState === "visible") {
            await nowStore.getSyncTime();
        }
    });
});
</script>

<template>
    <TonConnectUIProvider :options="options">
        <component :is="router.currentRoute.value.meta.layout">
            <router-view />
        </component>
    </TonConnectUIProvider>
</template>
