import libIMG from "./imgConstant";

export default [
    { img: libIMG.CoinCheckIn1 },
    { img: libIMG.CoinCheckIn2 },
    { img: libIMG.CoinCheckIn3 },
    { img: libIMG.CoinCheckIn4 },
    { img: libIMG.CoinCheckIn4 },
    { img: libIMG.CoinCheckIn4 },
    { img: libIMG.CoinCheckIn5 },
];
