export default function detectPlatform() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for Android
    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // Check for iOS (iPhone/iPad)
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    // Check for Windows or Mac (Desktop)
    // if (/windows/i.test(userAgent)) {
    //     return "Windows";
    // }

    // if (/macintosh|mac os x/i.test(userAgent)) {
    //     return "Mac";
    // }

    // Default to PC
    return "PC";
}
