<template>
    <div
        class="flex flex-col justify-center items-center w-full py-8 rounded-md animate"
    >
        <div class="flex justify-center items-center">
            <img
                src="../assets/imgs/xmark_icon.png"
                class="w-[28px] h-[28px] mr-3 rounded-full"
            />
            <p class="text-2xl text-white">Login error</p>
        </div>

        <p class="text-red-600 mt-2 font-neue-bold">
            You do not have permission to login !
        </p>
    </div>
</template>

<style scoped>
.animate {
    background-color: rgba(0, 0, 0, 0.4);
    transition: all;
    animation-duration: 0.5s;
    animation-name: animate-pop;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
    animation-fill-mode: backwards;
}
@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}
</style>
