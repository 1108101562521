<template>
    <div class="w-full h-svh z-50" :class="showBoost ? 'block' : 'hidden'">
        <div class="fixed top-0 w-full h-full left-0 main-bg blur-sm"></div>
        <div
            class="fixed top-0 left-0 w-full h-full fade-in flex flex-col items-center justify-center"
        >
            <img
                :src="libIMG.XClose"
                alt="x close"
                class="self-end cursor-pointer mb-6 mr-4"
                @click.prevent="handleOnclick"
            />

            <div
                class="relative w-[90%] rounded-3xl"
                :class="[checkPlatform === 'Android' ? 'h-[70%]' : 'h-[80%]']"
            >
                <img
                    :src="libIMG.BoostBG"
                    class="absolute top-0 left-0 w-full h-full"
                    alt=""
                />
                <img
                    :src="libIMG.Ribbon"
                    alt="ribbon"
                    class="absolute -top-10 left-1/2 -translate-x-1/2"
                />
                <h1
                    class="absolute left-1/2 -translate-x-1/2 boost-text font-neue-bold"
                    :class="[
                        checkPlatform === 'PC'
                            ? '-top-[7%] '
                            : checkPlatform === 'Android'
                            ? '-top-[6%]'
                            : '-top-[5%] ',
                    ]"
                >
                    BOOST
                </h1>
                <div
                    class="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center mt-2"
                >
                    <h1 class="text-white font-neue-semibold text-[24px]">
                        Speed <span v-if="!isSetBoost">x2</span>
                    </h1>
                    <div class="relative mt-5">
                        <div
                            v-if="!isSetBoost"
                            class="absolute top-[-10px] right-[-20px] rounded-full text-white px-[6px] text-[12px]"
                            style="
                                background: linear-gradient(
                                    92deg,
                                    #fa409d 0%,
                                    #9a2fff 177.52%
                                );
                            "
                        >
                            x200%
                        </div>
                        <div
                            class="flex items-center justify-center rounded-full has-white-bg py-2 px-3 text-[14px]"
                        >
                            <img
                                :src="libIMG.Logo"
                                class="w-[18px] h-[18px] mr-1 mt-[-2px]"
                                alt="logo"
                            />
                            <span class="text-white font-neue-bold mr-[2px]">
                                {{
                                    !isBoosting
                                        ? formatNumber(_miningSpeedPerHour * 2)
                                        : formatNumber(_miningSpeedPerHour)
                                }}
                            </span>
                            <span class="opacity-30 text-white">/h</span>
                        </div>
                    </div>
                    <img
                        :src="libIMG.Rocket"
                        class="w-[40%] aspect-auto mt-5"
                        alt=""
                    />
                    <p
                        v-if="isWaiting || isBoosting"
                        class="text-white text-[20px] font-neue-bold"
                    >
                        {{
                            isBoosting ? "Boosting" : isWaiting ? "Waiting" : ""
                        }}
                    </p>
                    <p
                        v-show="isBoosting || isWaiting"
                        class="text-countdown text-white opacity-50 font-neue-bold mt-1 text-[18px]"
                    >
                        {{ showTime }}
                    </p>
                    <div
                        v-show="isBoosting || isWaiting"
                        class="relative w-[55%] rounded-full h-[20px] text-[#717171] font-neue-bold overflow-hidden mt-1 bg-transparent border-[2px] border-[#353041]"
                        style="background: rgba(88, 5, 46, 0.2)"
                    >
                        <div
                            class="absolute top-1/2 -translate-y-[50%] left-0 h-[24px] progress rounded-full"
                            :style="{ width: widthProgress + '%' }"
                        ></div>
                    </div>
                    <div
                        v-if="items"
                        class="mt-4 flex items-center justify-center w-[90%] gap-2"
                        :class="[isWaiting && '!mt-2', isBoosting && '!mt-2']"
                    >
                        <BoostItem
                            v-for="(item, index) in boostItems"
                            :key="index"
                            :id="item.id"
                            @handle-click-buy="handlePurchase"
                            :price="Number(item.price)"
                            :is-bought="item.is_bought"
                            :value="item.value"
                            :sub-title="item.props.sub_title"
                            :currency="item.props.currency"
                            :is-loading="isLoading"
                            :is-waiting="isWaiting"
                        />
                    </div>
                    <div class="loader my-8" v-else></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { toast } from "vue3-toastify";
import libIMG from "../constant/imgConstant";
import useCounterStore from "../stores/counter";
import useShowStore from "../stores/showStore";
import detectPlatform from "../utils/detectPlatform";
import useUserAuthStore from "../stores/userAuthStore";
import useNowStore from "../stores/nowStore";
import useBoostTimeStore from "../stores/boostTimeStore";
import formatNumber from "../utils/formatNumber";
import { BOOST_TYPE } from "../constant/itemType";
import { ACTIVE_ITEM_STATUS } from "../constant/itemStatus";
import { BoostItem } from ".";

const counterStore = useCounterStore();
const userAuthStore = useUserAuthStore();
const boostTimeStore = useBoostTimeStore();
const nowStore = useNowStore();
const showStore = useShowStore();
const { showBoost } = storeToRefs(showStore);
const { totalCoin } = storeToRefs(counterStore);
const { game, items, isSetBoost, user, miningSpeedPerHour } =
    storeToRefs(userAuthStore);
const { endTimeBoost, endTimeWait } = storeToRefs(boostTimeStore);
const { now } = storeToRefs(nowStore);
const checkPlatform = ref(detectPlatform());
const showTime = ref("00:00:00");
const widthProgress = ref();
const timerID = ref();
const boostItems = ref();
const isLoading = ref(false);
const isBoosting = ref(false);
const isWaiting = ref(false);
const timerID2 = ref();

const handleOnclick = () => {
    showBoost.value = false;
};

const _miningSpeedPerHour = computed(() => {
    return (
        Number(game.value?.props.speed.value) *
        Number(game.value?.props.amount.value) *
        3600 *
        user.value.boost
    );
});

const handlePurchase = async (id) => {
    const _item = items.value?.find((item) => item.id === id);
    if (!_item || isLoading.value || isWaiting.value) return;
    try {
        isLoading.value = true;
        const result = await userAuthStore.buyItem(id, BOOST_TYPE);

        if (result) {
            toast.success("Bought successfully!");
            isBoosting.value = true;
            isWaiting.value = true;
            endTimeBoost.value = now.value + Number(_item.value) * 1000;
            user.value.balance = totalCoin.value; // snapshot point
            game.value.started_at = now.value; // set new started at for the game
            activateBoost(_item, endTimeBoost.value);
        }
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};

const activateBoost = (_item, endTime) => {
    if (!isSetBoost.value) {
        user.value.boost = _item.props.boost;
        isSetBoost.value = true;
    }

    timerID.value = setInterval(() => {
        updateCountdown(
            endTime,
            Number(_item.value),
            Number(_item.props.duration_wait),
            _item.props.boost
        );
    }, 1000);
};

const updateCountdown = (
    endTime,
    durationTime,
    durationWaitTime,
    speedBoost
) => {
    const remainingTime = endTime - now.value;

    if (remainingTime <= 0) {
        clearInterval(timerID.value);
        clearInterval(timerID2.value);
        isBoosting.value = false;
        if (isSetBoost.value) {
            user.value.boost = user.value.boost / speedBoost;
            user.value.balance = totalCoin.value; // snapshot point
            game.value.started_at = now.value; // set new started at for the game
            isSetBoost.value = false;
        }
        endTimeBoost.value = null;
        endTimeWait.value = null;

        if (durationWaitTime === 0) {
            isWaiting.value = false;
            return;
        }
        resetCountdown(durationWaitTime, speedBoost);
        return;
    }

    const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
    const seconds = Math.floor((remainingTime / 1000) % 60);

    // Format time as HH:MM:SS
    showTime.value =
        String(hours).padStart(2, "0") +
        ":" +
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0");

    // calculate the width progress
    widthProgress.value = (
        (remainingTime / (durationTime * 1000)) *
        100
    ).toFixed(1);
};

const resetCountdown = (durationWaitTime, speedBoost) => {
    if (durationWaitTime === 0) return;

    endTimeWait.value = now.value + Number(durationWaitTime) * 1000;

    timerID2.value = setInterval(() => {
        updateCountdown(endTimeWait.value, durationWaitTime, 0, speedBoost);
    }, 1000);
};

onMounted(async () => {
    if (!items.value) {
        await userAuthStore.getListItems();
    }

    boostItems.value = items?.value?.filter(
        (item) => item.type === BOOST_TYPE && item.status === ACTIVE_ITEM_STATUS
    );

    const _item = items?.value?.find(
        (item) => item.type === BOOST_TYPE && item.is_bought === true
    );

    if (!_item) return;

    // run on the first time when  user info have not update
    if (endTimeBoost.value && endTimeBoost.value > now.value) {
        isBoosting.value = true;
        isWaiting.value = true;
        // const { endTime } = timeBoost;
        activateBoost(_item, endTimeBoost.value);
        return;
    }

    if (endTimeWait.value && endTimeWait.value > now.value) {
        // const { endTime } = timeWait;
        isBoosting.value = false;
        isWaiting.value = true;
        timerID2.value = setInterval(() => {
            updateCountdown(
                endTimeWait.value,
                Number(_item.props.duration_wait),
                0,
                _item.props.boost
            );
        }, 1000);
        return;
    }

    // run on the second time when user info updated
    if (!user.value.props.end_boost_at) return;

    if (user.value.boost && Number(user.value.boost) > 1) {
        const endBoostingTime = new Date(
            user.value.props.end_boost_at
        ).getTime();
        const endWaitingTime =
            endBoostingTime + Number(_item.props.duration_wait) * 1000;

        if (endBoostingTime > now.value) {
            isBoosting.value = true;
            isWaiting.value = true;
            activateBoost(_item, endBoostingTime);
            return;
        }

        if (endWaitingTime > now.value) {
            isBoosting.value = false;
            isWaiting.value = true;
            timerID2.value = setInterval(() => {
                updateCountdown(
                    endWaitingTime,
                    Number(_item.props.duration_wait),
                    0,
                    _item.props.boost
                );
            }, 1000);
            return;
        }
    }
});

watch(
    items,
    () => {
        boostItems.value = items?.value?.filter(
            (item) =>
                item.type === BOOST_TYPE && item.status === ACTIVE_ITEM_STATUS
        );
    },
    { deep: true }
);

watch(
    _miningSpeedPerHour,
    (p) => {
        if (!isNaN(p)) {
            miningSpeedPerHour.value = p;
        }
    },
    { immediate: true }
);
</script>

<style scoped>
.main-bg {
    background: rgba(0, 0, 0, 0.5);
}

.boost-text {
    color: #fff;
    text-shadow: 0px 1.657px 1.42px #872ec9, -0.414px -0.414px 12.426px #fa409d,
        1.243px 1.243px 20.71px rgba(255, 255, 255, 0.35);
    font-size: 30px;
    font-weight: 700;
}

.has-white-bg {
    background: rgba(255, 255, 255, 0.1);
}

.text-countdown {
    text-shadow: 0px 0.854px 0.854px rgba(0, 0, 0, 0.25),
        0px 0.854px 0.854px rgba(0, 0, 0, 0.25),
        0px 0.854px 0.854px rgba(0, 0, 0, 0.25),
        -0.213px -0.213px 6.405px rgba(83, 83, 83, 0),
        0.64px 0.64px 10.675px rgba(255, 255, 255, 0.35);
    font-size: 14px;
}

.progress {
    background: linear-gradient(90deg, #fa409d 0%, #9a2fff 100%);
    background-size: 100%;
}
</style>
