import axios from "axios";
import { toast } from "vue3-toastify";

const backendURL = import.meta.env.VITE_BACKEND_URL;
const botName = import.meta.env.VITE_BOT_NAME;
const isLocal = import.meta.env.DEV;

const client = axios.create({
    baseURL: backendURL+'/api',
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});
if (isLocal) {
    client.defaults.baseURL = "/api";
}

export async function checkIn(token) {
    try {
        const response = await client.post(`/user/check-in/${botName}`, null, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
        });
        
        return response;
    } catch (error) {
        console.error("Error check-in:", error);
        const msg = error?.response?.message;
        if (typeof msg == "string") {
            toast.error(msg || "Something error");
            return;
        }
    }
}
