<script setup>
import { useRouter } from "vue-router";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { ProgressBar } from "../components";
import useUserAuthStore from "../stores/userAuthStore";
import useNowStore from "../stores/nowStore.js";
import libIMG from "../constant/imgConstant";
import isEmptyObj from "../utils/isEmptyObj.js";

const userAuthStore = useUserAuthStore();
const nowStore = useNowStore();
const { user } = storeToRefs(userAuthStore);
const { now } = storeToRefs(nowStore);
const router = useRouter();
const progress = ref(0);

onMounted(async () => {
    if (
        !window.Telegram.WebApp.initDataUnsafe ||
        isEmptyObj(window.Telegram.WebApp.initDataUnsafe)
    ) {
        router.push({ path: "/error" });
    }

    try {
        const telegramInitData = window.Telegram.WebApp.initDataUnsafe;
        await userAuthStore.getUserInfo(JSON.stringify(telegramInitData));
    } catch (err) {
        console.error(err);
    }
});

onMounted(async () => {
    try {
        await nowStore.getSyncTime();
    } catch (err) {
        console.error(err);
    }
});

onMounted(() => {
    const idTimer = setInterval(() => {
        progress.value += Math.floor(Math.random() * 11);
        if (progress.value >= 100) {
            progress.value = 100;
            clearInterval(idTimer);
        }
    }, 150);
});

onBeforeUnmount(async () => {
    //get config
    await userAuthStore.getConfig();

    //get list all tasks
    await userAuthStore.getTasks();

    //get list all items
    await userAuthStore.getListItems();

    //get list ranking user
    await userAuthStore.getLeaderBoardUser();

    //get list invited friends
    await userAuthStore.getListInvitedFriend();
});

watch([progress, user, now], () => {
    if (progress.value === 100 && user.value?.id && now.value) {
        router.push({ path: "/home" });
    }
});
</script>

<template>
    <div
        class="w-full min-h-svh flex flex-col items-center pt-2 pb-8 justify-between relative"
    >
        <img
            :src="libIMG.LoadingBG_1"
            class="absolute top-0 left-0 w-full h-svh"
            alt="main bg"
        />
        <div class="flex flex-col items-center mt-8 z-20">
            <img :src="libIMG.LogoLoading" width="280" height="auto" />
        </div>

        <div class="w-[70%] z-20 mb-10">
            <ProgressBar :completed="progress" :title="'Loading...'">
            </ProgressBar>
        </div>
    </div>
</template>
