import BottomLogo from "../assets/imgs/bottom_logo.svg";
import Logo from "../assets/imgs/logo.png";
import Telegram from "../assets/imgs/telegram.png";
import Instagram from "../assets/imgs/instagram.png";
import X from "../assets/imgs/X.png";
import No_Avatar from "../assets/imgs/no_avatar.png";
import LvUp from "../assets/imgs/lv_up.png";
import Ton from "../assets/imgs/ton.png";
import BD_T_1 from "../assets/imgs/bd_5.png";
import BD_T_2 from "../assets/imgs/bd_4.png";
import BD_3 from "../assets/imgs/bd_3.png";
import TagIcon from "../assets/imgs/tag_icon.png";
import BGHall from "../assets/imgs/bg_hall.png";
import ExitSVG from "../assets/imgs/exit.svg";
import Arrow from "../assets/imgs/arrow_1.png";
import Arrow_2 from "../assets/imgs/arrow_2.png";
import Wallet from "../assets/imgs/wallet_icon.svg";
import Vehicle_3 from "../assets/imgs/v_3.png";
import Food_1 from "../assets/imgs/f_1.png";
import Character1 from "../assets/imgs/c_1.png";
import Character2 from "../assets/imgs/c_2.png";
import Character3 from "../assets/imgs/c_3.png";
import Stroke from "../assets/imgs/stroke.png";
import StarCharacter from "../assets/imgs/star_character.png";
import BigCoin from "../assets/imgs/big_coin.png";
import Coin1 from "../assets/imgs/coin_1.png";
import Coin2 from "../assets/imgs/coin_2.png";
import Coin3 from "../assets/imgs/coin_3.png";
import Coin4 from "../assets/imgs/coin_4.png";
import Coin5 from "../assets/imgs/coin_5.png";
import Coin6 from "../assets/imgs/coin_6.png";
import CheckMission from "../assets/imgs/check_mission.svg";
import BG_1 from "../assets/imgs/bg_1.png";
import BG_2 from "../assets/imgs/bg_2.png";
import BG_3 from "../assets/imgs/bg_3.png";
import BG_4 from "../assets/imgs/bg_4.png";
import BG_5 from "../assets/imgs/bg_5.png";
import Rotate from "../assets/imgs/rotate.svg";
import LoadingBG_1 from "../assets/imgs/bg_loading_1.png";
import LogoLoading from "../assets/imgs/logo_loading.png";
import BottomNavbarBG from "../assets/imgs/bottom-navbar.png";
import BackButton from "../assets/imgs/back-btn.png";
import Weapon_2 from "../assets/imgs/weapon_2.png";
import HallIcon from "../assets/imgs/hall_icon.svg";
import GameIcon from "../assets/imgs/game_icon.svg";
import FriendIcon from "../assets/imgs/friend_icon.svg";
import MissionIcon from "../assets/imgs/mission_icon.svg";
import HallIcon2 from "../assets/imgs/hall_icon_2.svg";
import GameIcon2 from "../assets/imgs/game_icon_2.svg";
import FriendIcon2 from "../assets/imgs/friend_icon_2.svg";
import MissionIcon2 from "../assets/imgs/mission_icon_2.svg";
import BottomBar from "../assets/imgs/bottom_bar.svg";
import HomeTopBar from "../assets/imgs/home_top_bar.png";
import HallEarn from "../assets/imgs/hall_earn.png";
import HallStar from "../assets/imgs/hall_star.png";
import HallBoost from "../assets/imgs/hall_boost.png";
import HallAds from "../assets/imgs/hall_ads.png";
import LogoGame from "../assets/imgs/logo_game.png";
import Board from "../assets/imgs/board.png";
import XClose from "../assets/imgs/x_close.svg";
import Ribbon from "../assets/imgs/ribbon.svg";
import Rocket from "../assets/imgs/rocket.png";
import BoostBG from "../assets/imgs/bg_boost.png";
import Avatar from "../assets/imgs/avatar.png";
import Calendar from "../assets/imgs/calendar.png";
import CheckYellow from "../assets/imgs/check_yellow.png";
import CoinCheckIn1 from "../assets/imgs/coin_checkin_1.png";
import CoinCheckIn2 from "../assets/imgs/coin_checkin_2.png";
import CoinCheckIn3 from "../assets/imgs/coin_checkin_3.png";
import CoinCheckIn4 from "../assets/imgs/coin_checkin_4.png";
import CoinCheckIn5 from "../assets/imgs/coin_checkin_5.png";
import TopCheckInItem from "../assets/imgs/top_check_in_item.png";

export default {
    Logo,
    Avatar,
    Board,
    Weapon_2,
    Vehicle_3,
    Food_1,
    BottomLogo,
    Telegram,
    Instagram,
    X,
    No_Avatar,
    Ton,
    LvUp,
    BD_T_1,
    BD_T_2,
    BD_3,
    TagIcon,
    Stroke,
    StarCharacter,
    LogoGame,
    ExitSVG,
    BGHall,
    Arrow,
    Arrow_2,
    Wallet,
    Character1,
    Character2,
    Character3,
    BigCoin,
    Coin1,
    Coin2,
    Coin3,
    Coin4,
    Coin5,
    Coin6,
    CheckMission,
    BG_1,
    BG_2,
    BG_3,
    BG_4,
    BG_5,
    Rotate,
    LoadingBG_1,
    LogoLoading,
    BottomNavbarBG,
    BackButton,
    HallIcon,
    GameIcon,
    FriendIcon,
    MissionIcon,
    BottomBar,
    HomeTopBar,
    HallEarn,
    HallStar,
    HallBoost,
    HallAds,
    HallIcon2,
    GameIcon2,
    FriendIcon2,
    MissionIcon2,
    XClose,
    Ribbon,
    Rocket,
    BoostBG,
    Calendar,
    CheckYellow,
    CoinCheckIn1,
    CoinCheckIn2,
    CoinCheckIn3,
    CoinCheckIn4,
    CoinCheckIn5,
    TopCheckInItem,
};
