<script setup>
import { TopNavbar, BottomNavbar, ConnectTon, CheckIn } from "../components";
import libIMG from "../constant/imgConstant";
</script>

<template>
    <div class="max-w-screen w-full max-h-svh">
        <img
            :src="libIMG.BG_1"
            class="fixed top-0 w-full h-svh left-0 -z-[2]"
            alt=""
        />

        <div class="fixed top-0 w-full h-svh left-0 -z-10 bg-black"></div>

        <TopNavbar />
        <slot />
        <BottomNavbar />
    </div>
    <ConnectTon />
    <CheckIn />
</template>
