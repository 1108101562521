<template>
    <div
        ref="target"
        class="max-w-full cursor-pointer"
        :class="[checkPlatform !== 'PC' ? 'max-h-[340px]' : 'max-h-[300px]']"
    ></div>
</template>

<script setup>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { onMounted, ref, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import useUserAuthStore from "../stores/userAuthStore";
import getLV from "../utils/getLV";
import detectPlatform from "../utils/detectPlatform";

// Create a cache object for storing loaded models
const modelCache = {};

const checkPlatform = ref(detectPlatform());
const userAuthStore = useUserAuthStore();
const { game } = storeToRefs(userAuthStore);
const target = ref(null);
let scene, camera, renderer, controls, object;

// Scene Setup
const initScene = () => {
    scene = new THREE.Scene();

    camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
    );
    scene.add(camera);

    // Renderer setup with alpha (transparent background)
    renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(
        window.innerWidth - 40,
        checkPlatform.value !== "PC" ? 380 : 300
    );
    target.value.appendChild(renderer.domElement);

    // Camera controls
    controls = new OrbitControls(camera, renderer.domElement);
    controls.autoRotate = true;
    controls.autoRotateSpeed = 1;
    controls.enableDamping = true; // Smooth out camera movements
    controls.maxPolarAngle = Math.PI / 2;
    controls.minPolarAngle = Math.PI / 2;

    if (checkPlatform.value === "PC") {
        controls.minDistance = 10;
        controls.maxDistance = 50;
    }

    // Lighting
    const directionalLight = new THREE.DirectionalLight(0xffffff, 3.5); // Increased intensity for stronger lighting
    directionalLight.position.set(10, 30, 20); // Positioned higher and closer to the model
    scene.add(directionalLight);

    const ambientLight = new THREE.AmbientLight(0xffffff, 3.5); // Increased intensity for a brighter overall light
    scene.add(ambientLight);
};

// Load the 3D model with caching
const loadModel = () => {
    let objToRender = getLV(game.value?.props.storage.level)?.objToRender;
    const modelPath = `/models/${objToRender}/scene.gltf`;

    // Check if the model is already cached
    if (modelCache[modelPath]) {
        // Use cached model
        object = modelCache[modelPath];
        adjustModelPositionAndScale(object);
        scene.add(object); // Add the model to the scene
    } else {
        // Load model and cache it
        const loader = new GLTFLoader();
        loader?.load(
            modelPath,
            (gltf) => {
                object = gltf.scene;
                modelCache[modelPath] = object; // Cache the loaded model
                adjustModelPositionAndScale(object);
                scene?.add(object); // Add the model to the scene
            },
            (xhr) => console.log((xhr.loaded / xhr.total) * 100 + "% loaded"),
            (error) => console.error(error)
        );
    }

    // Adjust camera position based on the object
    switch (objToRender) {
        case "bd_1":
            camera.position.z = 35;
            break;
        case "bd_2":
            camera.position.z = 25;
            break;
        default:
            camera.position.z = 45;
    }
};

// Adjust model position and scale based on platform
const adjustModelPositionAndScale = (model) => {
    model.scale.set(1, 1, 1);

    const box = new THREE.Box3().setFromObject(model);
    const center = box.getCenter(new THREE.Vector3());
    model.position.sub(center); // Center the model

    // Set a slight elevation for mobile screens
    if (checkPlatform.value !== "PC") {
        model.position.y += 1;
    }
};

// Animation Loop
const animate = () => {
    requestAnimationFrame(animate);

    // Ensure controls are initialized before updating them
    if (controls) {
        controls.update();
    }

    if (renderer && scene && camera) {
        renderer.render(scene, camera);
    }
};

onMounted(() => {
    initScene();
    loadModel(); // Load model (from cache or fetch it if not cached)
    animate();
});

onBeforeUnmount(() => {
    // Clean up listeners and objects
    if (renderer) {
        renderer.dispose();
    }
    scene = null;
    camera = null;
    controls = null;
});
</script>
