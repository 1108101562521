<template>
    <div
        class="h-[calc(100svh-150px)] flex flex-col items-center justify-center relative has-top-border"
    >
        <Board
            :sub-class="'top-[25%] left-[10%]'"
            :_type="POINT_TYPE"
            :title="'Point'"
        />

        <Board
            :sub-class="'top-[7%] left-1/2 -translate-x-1/2'"
            :_type="CRYSTALS_TYPE"
            :title="'Crystals'"
        />

        <Board
            :sub-class="'top-[20%] right-[12%]'"
            :_type="STORAGE_TYPE"
            :title="'Building'"
        />

        <Board
            :sub-class="'bottom-[25%] left-[30%] '"
            :_type="SPEED_TYPE"
            :title="'Speed'"
        />

        <CornerIcon
            :title="'Earn'"
            :position="'top-[10px] left-[10px]'"
            :path="COMING_NAME"
        >
            <img :src="libIMG.HallEarn" alt="hall earn" />
        </CornerIcon>

        <CornerIcon
            :title="'Boost'"
            :position="'top-[10px] right-[10px]'"
            :path="BOOST_NAME"
        >
            <img :src="libIMG.HallBoost" alt="hall boost" />
        </CornerIcon>

        <CornerIcon
            :title="'Airdrop'"
            :position="'bottom-[10px] left-[10px]'"
            :path="AIRDROP_NAME"
        >
            <img :src="libIMG.HallStar" alt="hall star" />
        </CornerIcon>

        <CornerIcon
            :title="'Ads'"
            :position="'bottom-[10px] right-[10px] '"
            :path="COMING_NAME"
        >
            <img :src="libIMG.HallAds" alt="hall ads" />
        </CornerIcon>
    </div>

    <Boost />
    <Airdrop />
</template>

<script setup>
import libIMG from "../constant/imgConstant";
import { CornerIcon } from "../components";
import { BOOST_NAME, AIRDROP_NAME, COMING_NAME } from "../constant/showName";
import { Boost, Airdrop, Board } from "../components";
import {
    CRYSTALS_TYPE,
    POINT_TYPE,
    SPEED_TYPE,
    STORAGE_TYPE,
} from "../constant/itemType";
</script>
