<template>
    <div
        class="w-full min-h-[65px] flex items-center justify-center my-[6px] fade-in rounded-xl"
        :class="isBought && 'opacity-5'"
        style="
            background: linear-gradient(
                180deg,
                rgba(254, 219, 228, 0.2) 0%,
                rgba(195, 203, 254, 0.2) 100%
            );
        "
    >
        <slot></slot>

        <div
            class="w-full flex-1 flex justify-between items-center h-[55px] ml-2"
        >
            <div class="text-white">
                <h5 class="font-neue-bold text-[16px] leading-5">
                    {{ name && name }}
                </h5>
                <p class="text-[14px] opacity-70 leading-4">
                    {{ description && description }}
                </p>
            </div>

            <button
                class="rounded-full min-w-[75px] h-[30px] ml-6 flex justify-center items-center bg-gradient mr-2"
                :class="isBought && 'pointer-events-none opacity-50'"
                @click.prevent="handlePayment"
                :disable="isBought"
            >
                <div class="loader" v-if="isLoading"></div>

                <div v-else class="flex justify-center items-center">
                    <span
                        v-if="isBought"
                        class="text-[14px] gray-text font-neue-bold"
                        >Bought</span
                    >

                    <div class="flex justify-center items-center" v-else>
                        <img
                            :src="libIMG.Logo"
                            alt="coin"
                            class="rounded-full mr-[2px] w-[16px] h-[16px]"
                            :class="checkPlatform !== 'PC' && '-mt-[2px]'"
                        />
                        <span
                            class="text-[14px] font-neue-bold text-white"
                            style="
                                text-shadow: 0.64px 0.64px 10.675px
                                    rgba(255, 255, 255, 0.35);
                            "
                            >{{ price && formatNumber(price) }}</span
                        >
                    </div>
                </div>
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import libIMG from "../constant/imgConstant";
import { toast } from "vue3-toastify";
import useUserAuthStore from "../stores/userAuthStore";
import formatNumber from "../utils/formatNumber";
import detectPlatform from "../utils/detectPlatform";

const checkPlatform = ref(detectPlatform());

const { id, isBought, type } = defineProps({
    id: { type: Number, required: true },
    name: { type: String, required: true },
    description: { type: String, required: true },
    price: { type: Number, required: true },
    isBought: {
        type: Boolean,
        required: true,
    },
    type: { type: String, required: true },
    value: { type: [String, Number], required: true },
});

const isLoading = ref(false);
const userAuthStore = useUserAuthStore();

const handlePayment = async () => {
    if (isBought || isLoading.value) return;

    try {
        isLoading.value = true;
        const result = await userAuthStore.buyItem(id, type);
        if (result) {
            toast.success("Bought successfully!");
        }
        isLoading.value = false;
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};
</script>

<style scoped>
.bg {
    background: rgba(255, 255, 255, 0.3);
}

.gray-text {
    background: linear-gradient(180deg, #fbf5b0 0%, #ffffff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 1px 1px 0.5px rgba(65, 157, 255, 0.25);
}
</style>
