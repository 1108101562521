<template>
    <div
        class="gradient-bg z-[999] w-full flex flex-col justify-between items-center fixed bottom-0 right-0 transition-all ease-in-out duration-200 rounded-t-2xl"
        :class="
            showConnectTon
                ? checkPlatform !== 'PC'
                    ? 'h-[45%]'
                    : 'h-[55%]'
                : 'h-0'
        "
    >
        <div class="flex flex-col items-center justify-start w-full">
            <div
                class="text-[#f5f0f0] self-end cursor-pointer p-3 font-semibold"
                @:click="handleOnclickClose"
            >
                <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                >
                    <path
                        d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                    />
                </svg>
            </div>
            <img
                :src="libIMG.Ton"
                alt="ton logo"
                class="w-[100px] aspect-auto -mt-6"
            />
            <div
                class="text-[20px] pink-neon-text flex justify-center items-center font-neue-bold text-center uppercase"
            >
                {{ !wallet ? "Connect your wallet" : "Wallet connected" }}
            </div>
            <div
                v-if="wallet"
                class="flex flex-col justify-center items-center mt-2 py-3 px-6 rounded-lg"
                style="background: rgba(255, 255, 255, 0.15)"
            >
                <h1 class="text-white opacity-60 text-[14px]">
                    Wallet Address
                </h1>
                <p class="text-white text-[16px] font-semibold mt-[2px]">
                    {{ walletAddress }}
                </p>
            </div>
            <div
                v-else
                class="mt-1 text-[16px] text-center px-4 w-[280px] font-semibold text-white leading-6"
            >
                Connect to your TON wallet and get bonus
                <img
                    :src="libIMG.Logo"
                    alt="logo"
                    class="w-[18px] h-[18px] -mt-1 rounded-full inline-block mr-1"
                />
                <span class="yellow-neon-text font-neue-bold text-[18px]"
                    >1,000</span
                >.
            </div>
        </div>

        <button
            @:click="handleConnectTon"
            type="button"
            class="bg-gradient text-white text-[15px] w-[70%] h-[42px] rounded-full font-semibold mb-7 uppercase"
        >
            {{ !wallet ? "Connect TON Wallet" : "Disconnect wallet" }}
        </button>
    </div>
</template>

<script setup>
import { toast } from "vue3-toastify";
import { onBeforeMount, ref, watch } from "vue";
import {
    useTonConnectModal,
    useTonConnectUI,
    useTonWallet,
} from "@townsquarelabs/ui-vue";
import { storeToRefs } from "pinia";
import libIMG from "../constant/imgConstant";
import useShowStore from "../stores/showStore";
import detectPlatform from "../utils/detectPlatform";

const showStore = useShowStore();
const { showConnectTon } = storeToRefs(showStore);
const [tonConnectUI] = useTonConnectUI();
const wallet = useTonWallet();
const { open } = useTonConnectModal();
const walletAddress = ref();
const checkPlatform = ref(detectPlatform());

function formatString(str) {
    const start = str.substring(0, 6); // Get the first 6 characters
    const end = str.substring(str.length - 4); // Get the last 4 characters
    const dots = "......"; // Define dots

    // Return the formatted string
    return `${start}${dots}${end}`;
}

const handleOnclickClose = () => {
    showConnectTon.value = false;
};

const handleConnectTon = async () => {
    if (!wallet.value?.account?.address) {
        open();
    } else {
        await tonConnectUI.disconnect();
        toast.success("Disconnect your wallet successfully!");
    }
    isShowed.value = !isShowed.value;
};

onBeforeMount(() => {
    if (!wallet.value?.account?.address) return;
    walletAddress.value = formatString(wallet.value.account.address);
});

watch(wallet, () => {
    if (!wallet.value?.account?.address) return;
    walletAddress.value = formatString(wallet.value.account.address);
});

defineProps({
    isShowed: { type: Boolean },
});
</script>

<style scoped>
.gradient-bg {
    background: rgb(36, 35, 35)
        linear-gradient(rgba(37, 33, 51, 0.98) 5%, #100e23 90.2%);
}
</style>
