<template>
    <div
        class="h-[calc(100svh-150px)] w-full px-4 z-10 has-top-border flex flex-col items-center overflow-y-auto"
        :class="checkPlatform !== 'PC' && 'justify-center'"
    >
        <div
            v-if="!isShowInviteList"
            class="flex flex-col w-full items-center justify-center mt-2 gap-1"
        >
            <p class="text-[20px] font-neue-bold uppercase pink-neon-text">
                Invite a friend
            </p>

            <p class="text-white text-[14px] opacity-70 text-left">
                Get bonus when a friend joins and levels up.
            </p>
            <div class="flex items-center justify-center mt-1 gap-2">
                <p
                    class="text-white rounded-full px-4 cursor-pointer text-[14px] h-[28px] flex items-center justify-center"
                    style="background-color: rgba(255, 255, 255, 0.2)"
                    @click.prevent="showInviteCode"
                >
                    <span v-if="showUserCode" class="text-white font-neue-bold">
                        {{ user?.ref_code }}
                    </span>
                    <span
                        v-else
                        class="font-neue-bold white-pink-neon-text cursor-pointer"
                    >
                        Your Code!
                    </span>
                </p>
                <p
                    v-if="!isShowInviteList"
                    class="white-pink-neon-text flex items-center justify-center text-[14px] cursor-pointer font-neue-bold bg-white/20 px-4 capitalize h-[28px] rounded-full"
                    @click.prevent="showListInvitedFriend"
                >
                    Show invite list
                </p>
            </div>
        </div>

        <div
            v-if="!isShowInviteList"
            class="flex flex-col items-center justify-center gap-4 mt-6 w-full"
        >
            <div
                class="friend-item flex w-full items-center justify-between min-h-[65px] rounded-lg"
            >
                <div class="w-[60%] p-2">
                    <div class="flex items-center">
                        <h1
                            class="text-[18px] font-neue-bold white-pink-neon-text"
                        >
                            +{{ config?.referral_code_reward.toLocaleString() }}
                        </h1>
                        <img
                            :src="libIMG.Logo"
                            alt="logo"
                            class="ml-1 w-[18px] h-[18px] rounded-full"
                            :class="checkPlatform !== 'PC' && '-mt-1'"
                        />
                    </div>
                    <h2 class="text-[14px] text-[#FFF] opacity-70 mt-[6px]">
                        For you and your friend
                    </h2>
                </div>
                <div class="relative w-[30%] flex items-center mr-3">
                    <img :src="libIMG.Character1" alt="character 1" />
                    <img
                        :src="libIMG.Character2"
                        alt="character 2"
                        class="absolute bottom-0 right-0"
                    />
                </div>
            </div>

            <div
                class="friend-item flex w-full items-center justify-between min-h-[65px] rounded-lg"
            >
                <div class="w-[60%] p-2">
                    <div class="flex items-center">
                        <h1
                            class="text-[18px] font-neue-bold white-pink-neon-text"
                        >
                            +{{
                                config?.reward_referral_premium.toLocaleString()
                            }}
                        </h1>
                        <img
                            :src="libIMG.Logo"
                            alt="logo"
                            class="ml-1 w-[18px] h-[18px] rounded-full"
                            :class="checkPlatform !== 'PC' && '-mt-1'"
                        />
                    </div>
                    <h2
                        class="text-[14px] text-[#FFF] opacity-70 mt-[6px] leading-[18px]"
                    >
                        For you and your friend if they have Telegram Premium
                    </h2>
                </div>
                <div class="relative w-[30%] flex mr-3 h-full">
                    <img
                        :src="libIMG.StarCharacter"
                        alt=""
                        class="w-[70px] aspect-auto absolute right-[15%] top-4"
                    />
                    <img
                        :src="libIMG.Character3"
                        alt="character 3"
                        class="w-[70px] aspect-auto absolute right-[15%] bottom-0"
                    />
                </div>
            </div>

            <div
                class="flex has-pink-border w-full items-center justify-between h-[115px] rounded-lg"
                style="box-shadow: 1px 1px 4px 0px rgba(255, 163, 163, 0.25)"
            >
                <div class="w-[60%] p-2 inner-content">
                    <div class="flex items-center justify-start">
                        <h1
                            class="text-[24px] font-neue-bold white-pink-neon-text l-1 flex items-center"
                        >
                            +{{
                                config?.reward_if_friend_level_up_storage.toLocaleString()
                            }}
                        </h1>
                        <img
                            :src="libIMG.Logo"
                            alt="logo"
                            class="ml-1 w-[22px] h-[22px]rounded-full"
                            :class="checkPlatform !== 'PC' && '-mt-1'"
                        />
                    </div>
                    <h2
                        class="text-[14px] mt-[6px] text-[#FFF] opacity-70 leading-[14px]"
                    >
                        When your friend levels up
                    </h2>
                </div>
                <div class="relative w-[30%] h-full flex">
                    <img
                        :src="libIMG.BD_3"
                        alt=""
                        class="absolute bottom-[2px] left-[-11%] w-[34px] aspect-auto"
                    />
                    <img
                        :src="libIMG.BD_T_2"
                        alt=""
                        class="absolute bottom-0 right-[33%] w-[48px] aspect-auto"
                    />
                    <img
                        :src="libIMG.BD_T_1"
                        alt=""
                        class="absolute bottom-[-4px] right-[-10%] w-[62px] aspect-auto"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="isShowInviteList"
            class="flex-col items-center justify-center mt-2 w-full fade-in"
        >
            <h1
                class="pink-neon-text text-center mt-1 text-[20px] font-neue-bold uppercase"
            >
                Invite List
            </h1>
            <div
                v-if="listInvitedFriend.length > 0"
                class="max-h-[330px] overflow-auto flex flex-col items-center gap-2 w-full mt-2 pb-2"
            >
                <div
                    class="flex items-center justify-start friend-item w-full rounded-md py-2 gap-2"
                    v-for="(item, index) in listInvitedFriend"
                    :key="index"
                >
                    <h1
                        class="text-white white-pink-neon-text w-[10%] ml-2 text-center text-[20px]"
                    >
                        {{ index + 1 }}
                    </h1>
                    <img
                        :src="item.avatar"
                        alt="avatar"
                        class="w-9 h-9 rounded-md"
                    />
                    <p class="w-[70%] text-white font-neue-bold leading-5 px-2">
                        {{ item.full_name }}
                    </p>
                </div>
            </div>
            <h1
                v-else
                class="text-white text-center mt-1 text-[16px] opacity-80 white-pink-neon-text"
            >
                You haven't invited any friends yet!
            </h1>
        </div>

        <div class="w-full mt-4">
            <div class="flex justify-between gap-2 w-full">
                <a
                    @click.prevent="handleInviteFriend"
                    class="w-[49%] flex items-center justify-center text-white rounded-full px-4 cursor-pointer font-neue-bold text-[14px] h-[36px]"
                    style="
                        background: linear-gradient(
                            180deg,
                            rgba(233, 231, 200, 0.1) 0%,
                            rgba(174, 197, 229, 0.1) 100%
                        );
                    "
                >
                    Invite your friend!
                </a>
                <div
                    class="bg-gradient w-[49%] flex items-center justify-center rounded-3xl text-white text-[14px] font-semibold cursor-pointer h-[36px]"
                    @click.prevent="handleCopyLink"
                >
                    {{ !isCopied ? "Copy Link" : "Link Copied" }}
                </div>
            </div>

            <div class="w-full mb-3">
                <p
                    v-if="!isShowInviteCodeInput && !user?.ref_by"
                    class="text-white text-center mt-1 text-[14px] opacity-70 cursor-pointer p-1"
                    @click.prevent="showInvite"
                >
                    Have an invite code or an invite link?
                </p>

                <div
                    class="relative w-[100%] mx-auto overflow-hidden mt-1"
                    v-if="isShowInviteCodeInput && !user?.ref_by"
                >
                    <input
                        type="text"
                        class="w-full h-[36px] px-4 rounded-full mt-1 focus:outline-none text-[14px]"
                        placeholder="Enter invite code ....."
                        v-on:keyup.enter="sendInviteCode"
                        v-model.trim="inviteInput"
                    />
                    <button
                        class="absolute h-[36px] right-[0px] top-[4px] bg-[#fa409d] text-white font-neue-bold text-lg px-5 py-2 rounded-full outline-none"
                        :class="{
                            'opacity-40 cursor-not-allowed': isDisable,
                        }"
                        :disabled="isDisable"
                        @click.prevent="sendInviteCode"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-send-fill"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { toast } from "vue3-toastify";
import useUserAuthStore from "../stores/userAuthStore";
import libIMG from "../constant/imgConstant";
import detectPlatform from "../utils/detectPlatform";
import { initUtils } from "@telegram-apps/sdk";

const utils = initUtils();
const tmaURL = import.meta.env.VITE_TMA_URL;
const checkPlatform = ref(detectPlatform());
const userAuthStore = useUserAuthStore();
const { user, listInvitedFriend, config } = storeToRefs(userAuthStore);
const isShowInviteCodeInput = ref(false);
const inviteInput = ref("");
const isDisable = ref(true);
const showUserCode = ref(false);
const isCopied = ref(false);
const text = "Join with me on this awesome Telegram mini app now !";
const shareURL = `${tmaURL}?startapp=${user?.value?.ref_code}`;
const isShowInviteList = ref(false);

const showInvite = () => {
    isShowInviteCodeInput.value = !isShowInviteCodeInput.value;
};

const showListInvitedFriend = () => {
    isShowInviteList.value = !isShowInviteList.value;
};

const handleInviteFriend = () => {
    utils.shareURL(shareURL, text);
};

const showInviteCode = () => {
    if (showUserCode.value) {
        navigator.clipboard.writeText(user?.value?.ref_code);
        toast.info("Copied to clipboard !");
        return;
    }
    showUserCode.value = !showUserCode.value;
};

const sendInviteCode = async () => {
    if (!inviteInput.value) {
        return;
    }
    try {
        let ref_code;
        const refCodeMatch = inviteInput.value.match(/startapp=([^&]*)/);

        if (refCodeMatch) {
            ref_code = refCodeMatch[1];
        } else {
            ref_code = inviteInput.value;
        }

        const result = await userAuthStore.sendInviteCode(ref_code);

        if (result) {
            toast.success("Apply invite code successfully !");
        }
        inviteInput.value = "";
    } catch (error) {
        console.error("Send invite code error: ", error);
    }
};

const handleCopyLink = () => {
    if (isCopied.value) return;

    isCopied.value = true;
    navigator.clipboard.writeText(shareURL);
    toast.info("Copied to clipboard !");
};

onMounted(async () => {
    if (!config.value) {
        await userAuthStore.getConfig();
    }
});

onMounted(async () => {
    //get list invited friends
    if (!listInvitedFriend.value) {
        await userAuthStore.getListInvitedFriend();
    }
});

watch(inviteInput, () => {
    if (inviteInput.value) {
        isDisable.value = false;
    } else {
        isDisable.value = true;
    }
});
</script>

<style scoped>
.friend-item {
    box-sizing: border-box;
    border: 1px solid rgba(156, 204, 255, 0.16);
    box-shadow: 1px 1px 4px 0px rgba(255, 163, 163, 0.25);
}

.has-pink-border {
    border: 1px solid #fa409d;
    box-shadow: 1px 1px 3.4px 0px #f394ff;
}
</style>
