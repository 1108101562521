import { defineStore } from "pinia";

export default defineStore("show", {
    state: () => ({
        showBoost: false,
        showAirdrop: false,
        showConnectTon: false,
        showComing: false,
        showCalendar: false,
    }),
});
