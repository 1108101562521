<template>
    <div
        class="relative w-[32%] h-[100px] rounded-lg flex flex-col items-center justify-center"
        :class="isWaiting && 'opacity-50'"
        style="
            border-radius: 12px;
            border: 2px solid rgba(255, 255, 255, 0.19);
            background: linear-gradient(
                92deg,
                rgba(250, 64, 157, 0.5) 0%,
                rgba(154, 47, 255, 0.5) 100%
            );
        "
    >
        <div
            v-if="subTitle"
            class="text-white absolute left-1/2 -translate-x-1/2 top-[-10px] px-3 text-[12px] w-[90%] text-center"
            style="
                border-radius: 26px;
                background: linear-gradient(92deg, #fa409d 0%, #9a2fff 177.52%);
            "
        >
            {{ subTitle }}
        </div>
        <h1 class="text-white font-neue-bold text[16px]">
            {{ Math.floor(value / 60) }} mins
        </h1>
        <button
            class="rounded-2xl mt-3 px-3 py-1 text-white cursor-pointer"
            @click.prevent="$emit('handleClickBuy', id)"
            :disabled="isWaiting"
            style="background: linear-gradient(92deg, #fa409d 0%, #9a2fff 100%)"
        >
            <div class="loader" v-if="isLoading"></div>
            <p v-else>
                {{
                    isBought && isWaiting
                        ? "Bought"
                        : price === 0
                        ? "Free"
                        : `${price} ${currency || ""}`
                }}
            </p>
        </button>
    </div>
</template>

<script setup>
defineProps({
    id: { type: Number, required: true },
    price: { type: Number, required: true },
    isBought: {
        type: Boolean,
        required: true,
    },
    isWaiting: {
        type: Boolean,
        required: true,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    value: { type: [String, Number], required: true },
    currency: { type: String, default: "" },
    subTitle: { type: String, default: "" },
});
</script>
