<template>
    <div class="flex items-center justify-center w-full mt-3 pr-4">
        <h1
            class="text-white font-neue-bold text-[28px] -mt-[22px] w-[20%] text-center"
        >
            {{ number }}
        </h1>
        <div
            class="flex-1 w-full pb-3 border-b-2 border-[#ffffff17] flex items-center justify-between"
        >
            <div class="flex w-[70%] items-center justify-start">
                <img
                    :src="avatar"
                    alt="avatar"
                    class="w-9 h-9 rounded-md mr-3"
                />
                <p
                    class="text-white text-wrap text-[14px] font-semibold leading-[18px] flex-1 overflow-auto pr-1"
                >
                    {{ username }}
                </p>
            </div>
            <div class="flex items-center justify-center">
                <img
                    :src="libIMG.Logo"
                    alt="avatar"
                    class="w-[18px] h-[18px] mr-1 mt-[-2px]"
                />
                <p
                    class="text-white text-[14px] font-semibold white-pink-neon-text"
                >
                    {{ formatNumber(Number(totalPoint)) }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import libIMG from "../constant/imgConstant";
import formatNumber from "../utils/formatNumber";
defineProps({
    number: { type: Number, required: true },
    avatar: { type: String, required: true },
    username: { type: String, required: true },
    totalPoint: { type: String, required: true },
});
</script>
