<template>
    <div class="absolute w-[120px] h-[45px] cursor-pointer" :class="subClass">
        <div class="relative">
            <img :src="libIMG.Board" class="w-full aspect-auto" alt="" />

            <div
                class="absolute w-full h-full top-0 left-0 p-[6px]"
                @click.prevent="() => handleOnClick(_type)"
            >
                <div class="flex items-center justify-between">
                    <h3
                        class="text-white text-[14px]"
                        :class="_type == CRYSTALS_TYPE && 'text-center w-full'"
                    >
                        {{ title }}
                    </h3>
                    <p
                        v-if="_type !== CRYSTALS_TYPE"
                        class="text-white text-[14px]"
                    >
                        {{
                            items?.filter(
                                (item) =>
                                    item.type === _type &&
                                    item.is_bought === true
                            ).length
                        }}/{{
                            items?.filter((item) => item.type === _type).length
                        }}
                    </p>
                </div>
                <div
                    v-if="_type !== CRYSTALS_TYPE"
                    class="flex items-center justify-center gap-[2px] mt-[6px]"
                >
                    <div
                        v-for="(item, index) in items?.filter(
                            (item) => item.type === _type
                        )"
                        :key="index"
                        class="item"
                        :class="
                            item.type === _type && item.is_bought === true
                                ? 'opacity-100'
                                : 'opacity-40'
                        "
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import useAuthStore from "../stores/userAuthStore";
import libIMG from "../constant/imgConstant";
import { CRYSTALS_TYPE } from "../constant/itemType";

defineProps({
    subClass: { type: String },
    _type: { type: String },
    title: { type: String },
});

const router = useRouter();
const useAuth = useAuthStore();
const { items } = storeToRefs(useAuth);

onMounted(async () => {
    if (!items.value) {
        await useAuth.getListItems();
    }
});

const handleOnClick = (typeName) => {
    router.push({ path: `/items/${typeName}` });
};
</script>

<style scoped>
.item {
    border-radius: 1.59px;
    background: #fff;
    flex: 1;
    height: 6px;
    flex-shrink: 0;
}
</style>
