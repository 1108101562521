<template>
    <div class="w-full h-svh z-50" :class="showComing ? 'block' : 'hidden'">
        <div class="fixed top-0 w-full h-full left-0 main-bg blur-sm"></div>
        <div
            class="fixed top-0 left-0 w-full h-full fade-in flex flex-col items-center justify-center"
        >
            <div class="relative w-[90%] rounded-3xl h-[60%]">
                <img
                    :src="libIMG.BoostBG"
                    class="absolute top-0 left-0 w-full h-full"
                    alt=""
                />
                <img
                    :src="libIMG.XClose"
                    alt="x close"
                    class="absolute top-[-10px] right-[-4px] cursor-pointer z-[100]"
                    @click.prevent="handleOnclick"
                />
                <div
                    class="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center mt-2"
                >
                    <h1 class="text-center boost-text">Coming Soon</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import libIMG from "../constant/imgConstant";
import useShowStore from "../stores/showStore";

const showStore = useShowStore();
const { showComing } = storeToRefs(showStore);

const handleOnclick = () => {
    showComing.value = false;
};
</script>

<style scoped>
.main-bg {
    background: rgba(0, 0, 0, 0.5);
}

.boost-text {
    color: #fff;
    text-shadow: 0px 1.657px 1.42px #872ec9, -0.414px -0.414px 12.426px #fa409d,
        1.243px 1.243px 20.71px rgba(255, 255, 255, 0.35);
    font-size: 36px;
    font-weight: 700;
}

</style>
