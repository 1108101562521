<script setup>
const { completed = 0, title } = defineProps({
    showBar: { type: Boolean },
    title: { type: String },
    bgcolor: { type: String },
    completed: { type: Number },
});
</script>

<template>
    <div class="w-full">
        <div
            class="w-full flex items-center justify-between blue-text text-[16px] font-semibold mb-[2px]"
        >
            <p class="ml-[6px]">
                {{ title }}
            </p>
            <p>
                {{ completed + "%" }}
            </p>
        </div>
        <div
            class="w-full h-6 is-parallelogram border-[1px] border-[#9cccff33] overflow-hidden"
        >
            <div
                class="h-full transition-all ease-in-out duration-150 bg-gradient"
                :style="`width:${completed}%;`"
            ></div>
        </div>
    </div>
</template>

<style scoped>
.is-parallelogram {
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    transform: skew(-20deg);
}
</style>
