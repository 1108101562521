import axios from "axios";
import { toast } from "vue3-toastify";

const backendURL = import.meta.env.VITE_BACKEND_URL;
const botName = import.meta.env.VITE_BOT_NAME;
const isLocal = import.meta.env.DEV;

const client = axios.create({
    baseURL: backendURL + "/api",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});
if (isLocal) {
    client.defaults.baseURL = "/api";
}

export const getTasks = async (token) => {
    try {
        const response = await client.get(`/task/${botName}`, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
        });
        if (response) return response;
    } catch (error) {
        console.error("Error getting tasks:", error);
        const msg = error?.response?.data?.message;
        if (typeof msg == "string") {
            toast.error(msg || "Something error");
        }
    }
};

export const sendStartTask = async (taskId, token) => {
    try {
        const response = await client.put(
            `/task/${taskId}/start/${botName}`,
            null,
            {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true,
            }
        );
        if (response) return response;
    } catch (error) {
        console.error("Error sending start task:", error);
        const msg = error?.response?.data?.message;
        if (typeof msg == "string") {
            toast.error(msg || "Something error");
        }
    }
};

export const sendCompleteTask = async (taskId, token) => {
    try {
        const response = await client.put(
            `/task/${taskId}/completed/${botName}`,
            null,
            {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true,
            }
        );
        if (response) return response;
    } catch (error) {
        console.error("Error sending complete task:", error);
        const msg = error?.response?.data?.message;
        if (typeof msg == "string") {
            toast.error(msg || "Something error");
        }
    }
};
