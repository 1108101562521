import axios from "axios";
import { toast } from "vue3-toastify";

const backendURL = import.meta.env.VITE_BACKEND_URL;
const isLocal = import.meta.env.DEV;

const client = axios.create({
    baseURL: backendURL + "/api",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

if (isLocal) {
    client.defaults.baseURL = "/api";
}

export async function getTimeServer(timeNow) {
    try {
        const response = await client.get(`/time`, {
            params: {
                timestamp: timeNow,
            },
        });
        if (response) return response?.data;
    } catch (error) {
        console.error("Error get user info:", error);
        const msg = error?.response?.data;
        if (typeof msg == "string") {
            toast.error(msg || "Something error");
            return;
        }
    }
}
