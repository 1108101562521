export default function getLV(lv) {
    let objToRender, lvText;
    switch (lv) {
        case 1:
            objToRender = "bd_1";
            lvText = "Cottage";
            break;
        case 2:
            objToRender = "bd_2";
            lvText = "Villa";
            break;
        case 3:
            objToRender = "bd_3";
            lvText = "Estate";
            break;
        case 4:
            objToRender = "bd_4";
            lvText = "Mansion";
            break;
        case 5:
            objToRender = "bd_5";
            lvText = "Fortress";
            break;
        default:
            objToRender = "bd_1";
            lvText = "Cottage";
            break;
    }

    return { objToRender, lvText };
}
