<template>
    <div class="max-w-screen w-full max-h-svh">
        <img
            :src="libIMG.BG_1"
            class="fixed top-0 w-full h-svh left-0 -z-[2]"
            alt=""
        />
        <img
            :src="libIMG.BG_2"
            class="fixed top-0 w-full h-svh left-0 -z-[3]"
            alt=""
        />
        <div class="fixed top-0 w-full h-svh left-0 -z-10 bg-black"></div>
        <div class="min-h-svh">
            <TopNavbar />
            <slot />
        </div>
    </div>
    <ConnectTon />
</template>

<script setup>
import { TopNavbar, ConnectTon } from "../components";
import libIMG from "../constant/imgConstant";
</script>
