<template>
    <div
        class="w-full min-h-svh flex flex-col items-center justify-center has-gradient px-4 bg"
    >
        <LoginError />
    </div>
</template>
<script setup>
import { LoginError } from "../components";
</script>
