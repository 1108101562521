<script setup>
import { useRouter } from "vue-router";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { BOOST_NAME, AIRDROP_NAME, COMING_NAME } from "../constant/showName";
import { ThreeDModel, CornerIcon } from "../components";
import useCounterStore from "../stores/counter";
import useUserAuthStore from "../stores/userAuthStore";
import useNowStore from "../stores/nowStore.js";
import useShowStore from "../stores/showStore";
import libIMG from "../constant/imgConstant";
import formatNumber from "../utils/formatNumber";
import getLV from "../utils/getLV";
import { STORAGE_TYPE } from "../constant/itemType.js";

const router = useRouter();
const nowStore = useNowStore();
const showStore = useShowStore();
const counterStore = useCounterStore();
const userAuthStore = useUserAuthStore();
const { widthProgress, totalCoin } = storeToRefs(counterStore);
const { user, game, config } = storeToRefs(userAuthStore);
const { now } = storeToRefs(nowStore);
const { showCalendar } = storeToRefs(showStore);
const lvText = ref(getLV(game.value?.props.storage.level)?.lvText);
const isMaximum = ref(false);

const currentPoint = computed(() => {
    const duration = (now.value - game.value?.started_at) / 1000;
    const miningSpeed =
        Number(game.value?.props.speed.value) *
        Number(game.value?.props.amount.value) *
        user.value.boost;

    const _totalPoint = duration * miningSpeed + Number(user.value.balance);

    if (_totalPoint < 0 || isNaN(_totalPoint)) return 0;

    if (
        _totalPoint >= Number(game.value?.props.storage.value) &&
        game.value?.props.storage.value !== null
    ) {
        isMaximum.value = true;
        return Number(game.value?.props.storage.value);
    }

    return _totalPoint;
});

const storageShow = computed(() => {
    if (
        game.value?.props.storage.value === null ||
        game.value?.props.storage.value === Infinity
    ) {
        return "Infinity";
    }

    if (!game.value?.props.storage.value) return 0;

    return formatNumber(Number(game.value?.props.storage.value));
});

const handleGoToStorageItems = () => {
    router.push({ path: `/items/${STORAGE_TYPE}` });
};

onMounted(async () => {
    if (!game.value) {
        try {
            await userAuthStore.startGame();
        } catch (error) {
            console.error(error);
        }
    }
});

onMounted(() => {
    //Set odometer
    new Odometer({
        el: document.querySelector(".odometer-balance"),
        value: totalCoin.value || 0,
        format: "(,ddd)",
    });
});

watch(
    currentPoint,
    (p) => {
        totalCoin.value = p;
    },
    { immediate: true }
);

watch(
    currentPoint,
    () => {
        if (
            game.value?.props.storage.value === null ||
            game.value?.props.storage.value === Infinity
        ) {
            widthProgress.value = 100;
            return;
        }

        if (!game.value?.props.storage.value) {
            return;
        }

        widthProgress.value = (
            (Number(currentPoint.value) /
                Number(game.value?.props.storage.value)) *
            100
        ).toFixed(1);
    },
    { immediate: true }
);

watch(
    [user, config],
    () => {
        if (!user.value.checked_in && config.value) {
            showCalendar.value = true;
        }
    },
    { immediate: true }
);
</script>

<template>
    <div
        class="relative h-[calc(100svh-150px)] w-full overflow-hidden flex flex-col items-center justify-between"
    >
        <CornerIcon
            :title="'Earn'"
            :position="'top-[22%] left-[15px]'"
            :path="COMING_NAME"
        >
            <img :src="libIMG.HallEarn" alt="hall earn" />
        </CornerIcon>
        <CornerIcon
            :title="'Boost'"
            :position="'top-[22%] right-[15px]'"
            :path="BOOST_NAME"
        >
            <img :src="libIMG.HallBoost" alt="hall boost" />
        </CornerIcon>
        <CornerIcon
            :title="'Airdrop'"
            :position="'bottom-[15px] left-[15px]'"
            :path="AIRDROP_NAME"
        >
            <img :src="libIMG.HallStar" alt="hall star" />
        </CornerIcon>
        <CornerIcon
            :title="'Ads'"
            :position="'bottom-[15px] right-[15px] '"
            :path="COMING_NAME"
        >
            <img :src="libIMG.HallAds" alt="hall ads" />
        </CornerIcon>

        <div
            class="w-full flex flex-col items-center justify-center mt-1 pointer-events-none"
        >
            <div class="w-full flex flex-col items-center justify-center">
                <img
                    :src="libIMG.HomeTopBar"
                    alt=""
                    class="top-0 left-0 min-w-full aspect-auto"
                />
                <div
                    class="text-white flex items-center justify-center mt-[-32px]"
                >
                    <img
                        :src="libIMG.Logo"
                        class="w-[18px] h-[18px] rounded-full mr-[2px] mt-[-2px]"
                    />
                    <span class="odometer-balance font-bold text-[18px]">{{
                        totalCoin
                    }}</span>
                </div>
            </div>
            <div
                class="w-[65%] flex items-center justify-between mt-4 pointer-events-none"
                v-if="
                    game?.props?.storage?.level ||
                    game?.props?.storage?.level === 0
                "
            >
                <div class="flex items-center justify-center">
                    <h1 class="blue-text font-neue-bold mr-[6px] text-[18px]">
                        Lvl.
                        {{ game.props.storage.level }}
                    </h1>
                    <p
                        class="blue-text text-[12px] font-semibold uppercase mt-[2px]"
                    >
                        {{ lvText }}
                    </p>
                </div>
                <p class="blue-text font-neue-bold text-[16px] mr-1">
                    {{ storageShow }}
                </p>
            </div>
            <div
                class="relative w-[65%] rounded-full h-[30px] text-[#717171] font-neue-bold overflow-hidden mt-0 bg-transparent border-[2px] border-[#353041]"
                style="background: rgba(88, 5, 46, 0.2)"
                v-if="game"
            >
                <div
                    class="absolute top-1/2 -translate-y-[50%] left-0 h-[24px] progress rounded-full"
                    :style="{ width: widthProgress + '%' }"
                ></div>
            </div>
        </div>
        <ThreeDModel />
        <div class="h-8">
            <img
                v-if="!isMaximum"
                :src="libIMG.Rotate"
                alt="rotate"
                class="w-[100px] pointer-events-none"
            />
            <button
                @click.prevent="handleGoToStorageItems"
                v-else
                class="px-4 py-1 font-neue-bold text-white text-[14px] flex rounded-full animate-bounce"
                style="
                    background: linear-gradient(
                        rgba(233, 231, 200, 0.1) 0%,
                        rgba(174, 197, 229, 0.1) 100%
                    );
                "
            >
                Upgrade your storage !
            </button>
        </div>
    </div>
</template>

<style scoped>
.bounce-button {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    animation: bounce 2s infinite;
}

.bounce-button:hover {
    background-color: #2980b9;
}

.bounce-button {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    animation: bounce 2s infinite;
}

.bounce-button:hover {
    background-color: #2980b9;
}

.progress {
    background: linear-gradient(90deg, #fa409d 0%, #9a2fff 100%);
    background-size: 100%;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
</style>
