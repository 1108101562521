export default {
    daily: {
        name: "Daily Missions",
    },
    social: {
        name: "Social Tasks",
    },
    // metaverse: {
    //     name: "Metaverse Tasks",
    // },
    // metaverse_node_operation: {
    //     name: "Metaverse Nodes Operation",
    // },
    // out_metaverse: {
    //     name: "Our Metaverse",
    // },
};
