<template>
    <div v-if="status == SUCCESS">
        <img
            :src="IMGS.CheckMission"
            alt="check circle"
            width="26"
            height="26"
            class="mr-4"
        />
    </div>
    <div v-else class="p-4">
        <img :src="IMGS.TagIcon" alt="tag icon" class="w-6 h-6" />
    </div>
</template>

<script setup>
import IMGS from "../constant/imgConstant";
import { SUCCESS } from "../constant/taskStatus";

defineProps({
    status: { type: String },
});
</script>
