<template>
    <div
        class="flex flex-col items-center justify-center absolute cursor-pointer"
        :class="position"
        @click.prevent="handleOnclick"
    >
        <div
            class="w-[50px] h-[50px] flex items-center justify-center rounded-lg icon-item"
        >
            <div class="w-9 aspect-auto swing">
                <slot></slot>
            </div>
        </div>
        <h1 class="text-white font-neue-bold text-[14px] mt-[2px]">
            {{ title }}
        </h1>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import useShowStore from "../stores/showStore";
import { BOOST_NAME, AIRDROP_NAME, COMING_NAME } from "../constant/showName";

const showStore = useShowStore();
const { showBoost, showAirdrop, showConnectTon, showComing } =
    storeToRefs(showStore);

const { path } = defineProps({
    title: { type: String },
    position: { type: String },
    path: { type: String, default: "#" },
});

const handleOnclick = () => {
    if (path === "#") return;

    switch (path) {
        case BOOST_NAME:
            showBoost.value = !showBoost.value;
            showConnectTon.value = false;
            break;
        case AIRDROP_NAME:
            showAirdrop.value = !showAirdrop.value;
            showConnectTon.value = false;
            break;
        case COMING_NAME:
            showComing.value = !showComing.value;
            showConnectTon.value = false;
            break;
        default:
            break;
    }
};
</script>

<style scoped>
.icon-item {
    border-radius: 12px;
    background: rgba(34, 27, 47, 0.6);
    backdrop-filter: blur(1.0499999523162842px);
}
.swing {
    transform-origin: center center; /* Set the point around which the element will rotate */
    animation: swing 2s ease-in-out infinite;
}

@keyframes swing {
    0% {
        transform: rotate(0deg) scale(0.85);
    }
    25% {
        transform: rotate(15deg) scale(0.9);
    }
    50% {
        transform: rotate(-15deg) scale(0.95);
    }
    75% {
        transform: rotate(10deg) scale(0.9);
    }
    100% {
        transform: rotate(0deg) scale(0.85);
    }
}
</style>
